/* Main Font Imports */
@font-face {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 200..900;
  src: url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@200..900&display=swap");
  font-display: swap;
}

@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 200..1000;
  src: url("https://fonts.googleapis.com/css2?family=Nunito:wght@200..1000&display=swap");
  font-display: swap;
}

/* Global Styles */
html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Code Pro", "Nunito", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* Optional: Makes sizing elements easier */
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
